import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { inject, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { ElMessage } from 'element-plus';

var getDeviceFieldList = function getDeviceFieldList() {
  // 获取设备参数列表
  var $api = inject('$api');
  var options = ref([]);
  $api.getDeviceFieldList().then(function (data) {
    options.value = data;
  });
  return {
    options: options
  };
};

var changeFilter = function changeFilter(ctx) {
  // 改变筛选条件
  var filterData = reactive({
    // 热力图的日期、设备参数筛选字段
    time: '',
    field: ''
  });

  var updateHeatMap = function updateHeatMap() {
    // 更新热力图
    var diff = moment().isBefore(filterData.time);

    if (diff) {
      ElMessage('所选日期不能大于当前日期');
      return false;
    }

    ctx.emit('updateHeatMap', filterData);
  };

  return {
    filterData: filterData,
    updateHeatMap: updateHeatMap
  };
};

var handle = function handle() {
  // 设备地图按钮
  var router = useRouter();

  var showScatterMap = function showScatterMap() {
    // 切换到原始街道地图，即首页
    // const { href } = router.resolve({ name: 'home' })
    // window.open(href, '_blank')
    router.replace({
      path: '/'
    });
  };

  return {
    showScatterMap: showScatterMap
  };
};

export default {
  name: 'MapHandle',
  setup: function setup(props, ctx) {
    var _getDeviceFieldList = getDeviceFieldList(),
        options = _getDeviceFieldList.options;

    var _changeFilter = changeFilter(ctx),
        filterData = _changeFilter.filterData,
        updateHeatMap = _changeFilter.updateHeatMap;

    var _handle = handle(),
        showScatterMap = _handle.showScatterMap;

    return {
      options: options,
      filterData: filterData,
      updateHeatMap: updateHeatMap,
      showScatterMap: showScatterMap
    };
  }
};