export default function setHeatChartOptions (seriesData, timeList, max, min) {
  const optionsData = []
  seriesData.forEach(item => {
    const obj = {
      series: [{ data: item.data }]
    }
    optionsData.push(obj)
  })
  return {
    baseOption: {
      timeline: { // 开启时间线下方的时间线
        autoPlay: false, // 是否开启自动
        data: timeList,
        axisType: 'category',
        playInterval: 1000, // 跳转间隔时间
        lineStyle: {
          color: '#999999'
        },
        left: 20,
        right: 20,
        bottom: 10,
        label: { // 显示上方时间点标签的样式
          normal: {
            textStyle: {
              color: '#000000',
              fontSize: 14
            }
          }
        },
        controlStyle: {
          color: '#0095EA',
          borderColor: '#0095EA'
        }
      },
      bmap: {
        // center: [116.422545110, 39.917055306224895], // 当前显示地图的中心点
        zoom: 6, // 空间放大的倍数
        roam: true // 是否允许用户调节地图，如放大缩小
        // tooltip: { showDelay: 500, hideDelay: 500, transitionDuration: 0 }
      },
      visualMap: { // 用于开启自定义的视觉颜色条框
        show: false,
        min,
        max,
        inRange: {
          color: ['blue', 'blue', 'green', 'yellow', 'red']
        }
        // realtime: false
      },
      // animation: false,
      series: [{
        type: 'heatmap', // 类型为热力图  //设置为scatter才会显示文字提示
        coordinateSystem: 'bmap', // 采用的坐标系（地理坐标系）
        pointSize: 8, // 显示点的大小
        blurSize: 4, // 模糊显示点  其放大倍数越高，显示点的颜色越浅
        itemStyle: {
          normal: {
            color: '#ddb926'
          }
        }
      }]
    },
    options: optionsData
  }
}
