import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ef018ee6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  class: "main"
};
var _hoisted_3 = {
  ref: "chartDom",
  class: "chart_dom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_MapHandle = _resolveComponent("MapHandle");

  var _component_el_empty = _resolveComponent("el-empty");

  var _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, null, 512), (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_MapHandle, {
        ref: "mapHandle",
        onUpdateHeatMap: $setup.getHeatMap
      }, null, 8, ["onUpdateHeatMap"])];
    }),
    _: 1
  })), !$setup.heatMapList.length ? (_openBlock(), _createBlock(_component_el_empty, {
    key: 0,
    description: "暂无对应数据"
  })) : _createCommentVNode("", true)])), [[_directive_loading, $setup.isLoading]])]);
}